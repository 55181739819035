@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Overrides */

.ant-alert {
  z-index: 99999;
}

.site-layout .site-layout-background {
  background: #fff;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
}

.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table, .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  margin: 0 !important;
}