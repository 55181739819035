/* Mobile */

@media (max-width: 600px) {
  .ant-radio-group {
    /* margin-right: 5px; */
    display: "block";
    line-height: "30px";
    width: 100%;
  }

  .ant-radio-button-wrapper,
  .ant-picker {
    width: 100%;
    text-align: center;
    margin-bottom: 5px !important;
  }
}

/* Desktop */

@media (min-width: 600px) {
  .ant-radio-group {
    margin-right: 5px !important;
  }
}